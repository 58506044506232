body {
    color: #eee;
    font-family: arial, sans-serif;
}
.app {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    background: #000;
}
footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    font-size: x-small;
    color: #888;
    margin-bottom: 1em;
}
footer.dev {
    color: #e77;
}
footer.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 2s, opacity 2s linear;
}

.walkie {
    margin: auto;
    height: 100%;
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.map:focus {
    outline: none;
}
.map {
    border: 8px solid #232323;
}

.walkie .controls {
    margin-top: 15px;
    font-size: 20px;
    background: #232323;
    border-radius: 35px;
    padding: .5em;

    display: flex;
    justify-content: space-between;
    align-items: center;
}
.walkie .controls button {
    background: #27344f;
    border-radius: 50%;
    border: 0;
    font: inherit;
    color: inherit;
    width: 50px;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
}
.walkie .controls button:focus {
    outline: none;
}
.walkie .controls button:active {
    transition: all 0.1s;
    transform: scale(0.8);
    background-color: #37567c;
}

.win-level {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(-45deg, #1b482d, #484a47);
    position: relative;
}
.win-level button.next {
    background: #181;
    width: 100px;
    height: 100px;
    border: none;
    border-radius: 50%;
    font-size: 40px;
    color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
}
.win-level button.list {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: none;
    border: none;
    box-shadow: 0 0 10px 0px #5c8f71;
}
.win-level button:focus {
    outline: none;
}

.choose-level {
    align-items: center;
    background: linear-gradient(-45deg, #222, #333);
    position: relative;
    overflow-y: auto;
}
.choose-level .level-list {
    position: absolute;
    left: 0; right: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.choose-level button {
    background: #22494b;
    width: 44px;
    height: 44px;
    margin: 5px;
    border: none;
    border-radius: 50%;
    font-size: 22px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.choose-level button:focus {
    outline: none;
}
.choose-level button.current {
    background: #1d9336;
}

.popover {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: #111;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.popover .speech {
    display: flex;
    justify-content: center;
    align-items: center;
}
.popover .speech .who {
    font-size: 60px;
    margin-right: 25px;
    border-radius: 50%;
    background: #777;
    padding: 5px;
}
.popover .speech .what {
    font-size: 60px;
    position: relative;
    background: #999;
    border-radius: .2em;
    min-height: 2em;
    min-width: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
}
.popover .speech .what:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 19px solid transparent;
    border-right-color: #999;
    border-left: 0;
    margin-top: -19px;
    margin-left: -19px;
}
.popover > button {
    margin-top: 30px;
    background: #060;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    font-size: 30px;
    visibility: hidden;
}
.popover > button:focus {
    outline: none
}
.popover > button.show {
    visibility: visible;
}

.background {
    position: absolute;
}
.duckpond {
    width: 100%;
    height: 100%;
    background: #1b61b6;
    border-radius: 20px;
    position: relative;
    border: 5px solid sandybrown;
    box-sizing: border-box;
}
.duckpond .duck {
    position: absolute;
    font-size: 22px;
}
.player, .target, .wall, .lock, .figure, .numpad-lock, .door, .npc {
    display: flex;
    x-flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 30px;
    position: absolute;
}
.player {
    transition: transform 0.3s ease;
    transform: rotate(0deg);
}
.player.next {
    transform: rotate(90deg);
    transition: transform 0.3s ease;
}
.player.animate-eat {
    animation-name: eat;
    animation-duration: 0.3s;
}
@keyframes eat {
    0% {transform: scale(1);}
    50% {transform: scale(2);}
    100% {transform: scale(1);}
}
.player.animate-shake {
    animation: shake 0.1s 2;
}
@keyframes shake {
    0% {transform: translateX(0);}
    33% {transform: translateX(-6px);}
    67% {transform: translateX(6px);}
    100% {transform: translateX(0);}
}
.player.animate-drop {
    animation: drop 0.3s;
}
@keyframes drop {
    0% {transform: scale(1);}
    50% {transform: scale(0.7);}
    100% {transform: scale(1);}
}
.player.animate-teleport {
    animation: teleport-in 0.1s, teleport-out 0.1s;
    animation-delay: 0s, 0.1s;
}
@keyframes teleport-in {
    from {left: var(--cur-x); top: var(--cur-y); transform: scale(1);}
    to {left: var(--in-x); top: var(--in-y); transform: scale(0);}
}
@keyframes teleport-out {
    from {left: var(--out-x); top: var(--out-y); transform: scale(0);}
    to {left: var(--end-x); top: var(--end-y); transform: scale(1);}
}
.player .carry {
    position: absolute;
    top: 15px;
    left: -1px;
    font-size: 19px;
}
.player .carry .figure {
    transform: scale(0.5);
    border: 1px solid #000;
}

.wall {
    border-radius: 40%;
    border: 3px solid #333;
    background: #000;
    box-sizing: border-box;
}

.hint {
    text-shadow: 2px 2px 3px black;
    letter-spacing: 8px;
    font-weight: bold;
}
